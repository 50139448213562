import './style.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { isIOS, isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import { Badge, Button, Card, Col, Row } from 'reactstrap';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Link, withRouter } from 'react-router-dom';
import Rating from 'react-rating';
import { Button as MaterialButton, Checkbox, List, ListItem, ListItemText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Drawer from '@material-ui/core/Drawer';
import Hashids from 'hashids/cjs';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import safeAreaInsets from 'safe-area-insets';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Icon from '../../components/Icon';
import FancyBox from '../../components/fancybox';
import PageWrap from '../../components/page-wrap';
import RatingProgress from '../../components/RatingProgress';
import ActionSheet from '../../components/ActionSheet';
import ModalView from '../../components/ModalView';
import UserSearch from '../../components/user-search';
import RoutedTabs from '../../components/tabs';
import TabPanel from '../../components/TabPanel';
import ShareIntent from '../../utils/shareUtils';
import { addToast as actionAddToast } from '../../actions';
import Placeholder from '../../../config/placeholder.config';
import VenueEditor from '../VenueEditor';
import ShareItem from '../../models/ShareItem';
import { saveItem, unsaveItem } from '../SavedItems/helper';
import { mapStylesDark, mapStylesLight } from '../../components/map-listings';
import FeedCheckinCard from '../../components/FeedCheckinCard';
import BusinessPostCardList from '../../components/FeedBusinessPostCardList';
import { language, messages } from '../../utils/localeUtils';
import CheckinEditor from '../CheckinEditor';
import FeedEventCard from '../../components/FeedEventCard';
import ProductCardList from '../../components/ProductCardList';
import FeedSessionCard from '../../components/FeedSessionCard';
import CardListSkeleton from '../../components/CardList/skeleton';
import ReadMore from '../../components/ReadMore';
import MediaGrid from '../../components/media-grid';
import { SettingsStore, VenueStore } from '../../stores';
import { dateFromTimestamp } from '../../utils/formatting';
import PageMeta from '../../components/PageMeta';
import Venue from '../../models/Venue';
import PodcastEpisodeCard from '../../components/FeedPodcastEpisodeCard';
import BlogArticleCard from '../../components/CardList';
import { redirectAuth } from '../../utils/redirect';
import { Resize } from '../../utils/imageUtils';

const hashids = new Hashids('', 12);

// FIXME Just make this it's own file and default export the config line
const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

// FIXME If compact = true, make the font slightly smaller and padding less for rows in list - it overflows the row on my S20
export const renderAvailability = (venue, compact = false) => (
  <FormGroup row>
    <FormControlLabel
      control={
        <Checkbox checked={!!venue.can_buy} />
      }
      label="Buy"
    />
    <FormControlLabel
      control={
        <Checkbox checked={!!venue.can_buy_online} />
      }
      label="Buy Online"
    />
    <FormControlLabel
      control={
        <Checkbox checked={!!venue.can_smoke} />
      }
      label="Smoke"
    />
  </FormGroup>
);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const hasCoordinates = (venue) => venue.location.coordinate
  && venue.location.coordinate.latitude
  && venue.location.coordinate.latitude !== 0
  && venue.location.coordinate.longitude
  && venue.location.coordinate.longitude !== 0;

export function VenueDetails(props) {
  const [state, setState] = useState({
    activeTab: 'checkins',
    activeSecondaryTab: 0,
    showProfileUpload: false, // TODO Only if not mobile and user is authorized
    showMoreOptions: false,
    showCheckInModal: false,
    // showTaggedUsersModal: false, // TODO let users tag other users in their checkins
    loading: true,
    uploadingMedia: false,
    checkinImageThumbnails: [],
    selectedImageFile: null,
    selectedVideoFile: null,
    selectedImages: [],
  });

  const [isHome, setIsHome] = useState(false);
  const [venue, setVenue] = useState({});
  const [detailedRatings, setDetailedRatings] = useState({});
  const allCheckins = VenueStore.useState((s) => s.checkins);
  const [recentCheckins, setRecentCheckins] = useState([]);
  const [products, setProducts] = useState([]);
  const allSmokeSessions = VenueStore.useState((s) => s.sessions);
  const [smokeSessions, setSmokeSessions] = useState([]);
  const [posts, setPosts] = useState([]);
  const [events, setEvents] = useState([]);
  const [hours, setHours] = useState([]);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [checkinImages, setCheckinImages] = useState([]);
  const [checkinThumbnailImages, setCheckinThumbnailImages] = useState([]);
  const [images, setImages] = useState([]);
  const savedVenues = VenueStore.useState((s) => s.saved);
  const [itemSaved, setItemSaved] = useState(false);
  const followedVenues = VenueStore.useState((s) => s.following);
  const [itemFollowed, setItemFollowed] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const darkMode = SettingsStore.useState((s) => s.darkMode);

  console.log('followedVenues:');
  console.log(followedVenues);

  const moreOptions = [{
    content: `${messages[language]?.share || 'Share'} Venue`,
    value: 'share',
    onClick: () => {
      ShareIntent.share(new ShareItem({
        title: venue.name,
        text: `Check out the smoker-friendly venue, ${venue.name}, on Boxpressd!`,
        path: 'venue',
        route: `/venues/${hashids.encode(venue.id)}`,
        image: Venue.getImage(venue),
      }));
    },
  }, {
    // FIXME Only include this option if not already claimed
    content: 'Claim This Location',
    value: 'claim',
    onClick: () => {
      props.history.push(`/venues/claim?id=${hashids.encode(venue.id)}&type=venue`, { business: venue, business_type: 'venue' });
    },
  }, {
    content: messages[language]?.report_incorrect_data || 'Report Incorrect Data',
    value: 'report',
    onClick: () => {
      const { auth } = props;
      const user = auth.user || {};
      if (user && user.id) {
        setShowVenueModal(true);
      } else {
        redirectAuth(`${window.location.href}?action=report&itemId=${venue.id}`);
      }
    },
  }, {
    content: 'Embed',
    icon: 'code',
    value: 'embed',
    onClick: () => {
      window.open(`${config.embedEndPoint}?query=${config.appUrl}/venues/${hashids.encode(venue.id)}`, '_blank');
    },
  }];

  const getVenueDetails = (venueId) => {
    console.log('Getting venue from server...');
    if (venueId === 30938) {
      // This is Boxpressd at Home - no need for checkin ratings and other details - maybe add a button to create a smoke session
      setIsHome(true);
    }
    axios.get(`${config.apiEndPoint}/venues/${venueId}`).then((response) => {
      const venueData = response.data;
      console.log('Got venue from server:');
      console.log(venueData);

      if (window.analytics) {
        window.analytics.page('Venue Details', null, {
          item_id: venueData.id,
          item_type: 'venues',
          name: venueData.name,
        });
      }

      if (venueData.claimed) {
        moreOptions.splice(1, 1);
      }

      setState({
        ...state,
        loading: false,
      });
      setVenue(venueData);
    }).catch((err) => {
      console.log(err);
      setState({
        ...state,
        loading: false,
      });
    });
  };

  const getRatings = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/ratings`)
      .then((response) => {
        setDetailedRatings(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getProducts = (venueId) => {
    axios.get(`${config.shopEndPoint}/products/venues/${venueId}?sort=best_sellers`)
      .then((response) => {
        console.log('Venue products:');
        console.log(response.data);
        setProducts(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getCheckins = (venueId) => {
    // FIXME This endpoint needs to handle 'recent', 'older', 'negative', and 'positive' tab selections
    axios.get(`${config.apiEndPoint}/venues/${venueId}/checkins`)
      .then((response) => {
        // setRecentCheckins(response.data);
        VenueStore.update((s) => {
          s.checkins[venueId] = response.data;
        });
      }).catch((err) => {
        console.log(err);
      });
  };

  const getSessions = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/sessions`)
      .then((response) => {
        console.log('Venue Smoke Sessions:');
        console.log(response.data);
        // setSmokeSessions(response.data);
        VenueStore.update((s) => {
          s.sessions[venueId] = response.data;
        });
      }).catch((err) => {
        console.log(err);
      });
  };

  const getPosts = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/posts`)
      .then((response) => {
        setPosts(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getEvents = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/events`)
      .then((response) => {
        setEvents(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getHours = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/hours`)
      .then((response) => {
        setHours(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getVenueImages = (venueId) => {
    console.log('Getting venue images...');
    axios.get(`${config.apiEndPoint}/venues/${venueId}/images`)
      .then((response) => {
        console.log('Got venue images:');
        console.log(response.data);
        setImages(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getMedia = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/media`)
      .then((response) => {
        console.log('Media response:');
        console.log(response.data);
        const images = [];
        const thumbnailImages = [];
        if (response.data) {
          response.data.forEach((media) => {
            if (media.media_type === 'image') {
              images.push(media.url);
              thumbnailImages.push(Resize.thumbnail(media.url));
            }
          });
        }
        console.log(images);
        console.log(thumbnailImages);
        setCheckinImages(images);
        setCheckinThumbnailImages(thumbnailImages);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getAmenities = (venueId) => {
    axios.get(`${config.apiEndPoint}/venues/${venueId}/amenities`)
      .then((response) => {
        console.log('Got venue amenities:');
        console.log(response.data);
        setAmenities(response.data);
      }).catch((err) => {
        console.log(err);
      });
  };

  const getVenueParam = () => {
    const { match: { params } } = props;
    let { venueId } = params;
    if (venueId.indexOf('?') !== -1) {
      venueId = venueId.split('?')[0];
    }
    return hashids.decode(venueId.replace(/[\W_]+/g, ''))[0];
  };

  useEffect(() => {
    const decodedId = getVenueParam();
    console.log('Getting venue...');

    if (window.analytics) {
      const { auth } = props;
      const { user } = auth;
      if (decodedId && user && user.id) {
        if (window.analytics) {
          window.analytics.track('Venue Views', {
            venue_id: decodedId,
            user_id: user.id,
          });
        }
      }
    }

    if (decodedId === 30938) {
      setIsHome(true);
    }

    if (decodedId) {
      let foundVenue = false;
      if (props.location && props.location.state && props.location.state.venue) {
        foundVenue = true;
        setState({
          ...state,
          loading: false,
        });
        console.log('Found venue:');
        console.log(props.location.state.venue);
        setVenue(props.location.state.venue);
        if (props.location.state.venue.claimed) {
          moreOptions.splice(1, 1);
        }
        if (props.location.state.venue.images && props.location.state.venue.length) {
          setImages(props.location.state.venue.images);
        } else {
          getVenueImages(decodedId);
        }
      }

      if (!foundVenue) {
        getVenueImages(decodedId);
        getVenueDetails(decodedId);
      }
      getRatings(decodedId);
      getProducts(decodedId);
      getCheckins(decodedId);
      getSessions(decodedId);
      getPosts(decodedId);
      getEvents(decodedId);
      getHours(decodedId);
      getMedia(decodedId);
      getAmenities(decodedId);
    }
  }, [props.match.params.venueId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkInAction = !!urlParams.get('action') && urlParams.get('action') === 'checkin';

    if (checkInAction/* && !showCheckInModal */) {
      // toggleCheckInModal();
      setState({ ...state, showCheckInModal: true });
    }
  }, [window.location.search]);

  useEffect(() => {
    const { match: { params } } = props;
    const { venueId } = params;
    const decodedId = hashids.decode(venueId);
    setRecentCheckins(allCheckins[decodedId] || []);
    getVenueImages(decodedId); // FIXME This isn't the ideal solution
  }, [allCheckins]);

  useEffect(() => {
    const { match: { params } } = props;
    const { venueId } = params;
    const decodedId = hashids.decode(venueId);
    setSmokeSessions(allSmokeSessions[decodedId] || []);
  }, [allSmokeSessions]);

  useEffect(() => {
    const venueId = getVenueParam();
    if (venueId) {
      const saved = savedVenues.indexOf(venueId) !== -1 || savedVenues.indexOf(parseInt(venueId)) !== -1;
      setItemSaved(saved);
    }
  }, [savedVenues, props.match.params.venueId]);

  useEffect(() => {
    const venueId = getVenueParam();
    if (venueId) {
      const followed = followedVenues.indexOf(venueId) !== -1 || followedVenues.indexOf(parseInt(venueId)) !== -1;
      setItemFollowed(followed);
    }
  }, [followedVenues, props.match.params.venueId]);

  const venueProfileImage = (v) => Resize.size(Venue.getImage(v), { width: 150, height: 150, cropType: 'crop' });
  const venueCoverImage = (v) => Resize.size(Venue.getCoverImage(v), { width: 500, height: 'auto', cropType: 'fit' });

  const toggleSecondaryTab = (event, index) => {
    setState({
      ...state,
      activeSecondaryTab: index,
    });
  };

  const toggleCheckInModal = () => {
    const { auth } = props;
    const user = auth.user || {};
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('action');
    urlParams.delete('itemId');
    urlParams.delete('editId');
    props.history.replace({
      pathname: window.location.pathname,
      search: urlParams.toString(),
    });
    if (user && user.id) {
      setState({
        ...state,
        showCheckInModal: !state.showCheckInModal,
      });
    } else {
      redirectAuth(`${window.location.href}?action=checkin&itemId=${venue.id}`);
    }
  };

  const closeCheckInModal = () => {
    console.log('closeCheckInModal...');
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('action');
    urlParams.delete('itemId');
    urlParams.delete('editId');
    props.history.replace({
      pathname: window.location.pathname,
      search: urlParams.toString(),
    });
    setState({
      ...state,
      showCheckInModal: false,
    });
  };

  const toggleShopNowModal = () => {
    setState({
      ...state,
      showShopNowModal: !state.showShopNowModal,
    });
  };

  const closeShopNowModal = () => {
    setState({
      ...state,
      showShopNowModal: false,
    });
  };

  const toggleTaggedUsersModal = () => {
    setState({
      ...state,
      showTaggedUsersModal: !state.showTaggedUsersModal,
    });
  };

  const closeTaggedUsersModal = () => {
    setState({
      ...state,
      showTaggedUsersModal: false,
    });
  };

  const toggleMoreOptions = () => {
    console.log('Showing more options...');
    setState({
      ...state,
      showMoreOptions: !state.showMoreOptions,
    });
  };

  const toggleSaved = () => {
    const { auth } = props;
    const { user } = auth;

    if (user) {
      console.log('Toggling saved item for state:');
      if (!itemSaved) {
        console.log('save');
        saveItem(user, 'venue', venue);
      } else {
        console.log('unsave');
        unsaveItem(user, {
          item_id: venue.id,
          type: 'venue',
        });
      }

      props.addToast({
        content: (<>{itemSaved ? 'Removed from Saved Items' : 'Added to Saved Items'}</>),
        duration: 6000,
      });

      setItemSaved(!itemSaved);
    } else {
      redirectAuth(`${window.location.href}?action=save`);
    }
  };

  const toggleFollowing = () => {
    const { auth } = props;
    if (auth && auth.user) {
      if (itemFollowed) {
        setItemFollowed(false);
        axios.post(`${config.apiEndPoint}/venues/${venue.id}/unfollow`, { user: auth.user }).then(() => {
          VenueStore.update((s) => {
            s.following.splice(s.following.indexOf(parseInt(venue.id)), 1);
          });
          props.addToast({
            content: (<>{`Unfollowed ${venue.name}`}</>),
            duration: 3000,
          });
        }).catch(() => {
          setItemFollowed(true);
        });
      } else {
        setItemFollowed(true);
        axios.post(`${config.apiEndPoint}/venues/${venue.id}/follow`, { user: auth.user }).then(() => {
          VenueStore.update((s) => {
            s.following.unshift(parseInt(venue.id));
          });
          props.addToast({
            content: (<>{`Followed ${venue.name}`}</>),
            duration: 3000,
          });
        }).catch(() => {
          setItemFollowed(false);
        });
      }
    } else {
      redirectAuth(`${window.location.href}?action=follow`);
    }
  };

  const getDirections = (e) => {
    e.preventDefault();
    const coords = { lat: null, lng: null }; // TODO User's current location
    const origin = coords && coords.lat && coords.lng ? `${coords.lat},%20${coords.lng}` : '';
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${venue.location.coordinate.latitude},${venue.location.coordinate.longitude}`;
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'get_directions',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url,
      });
    }
    window.open(url, '_blank');
  };

  const callLocation = (e) => {
    e.preventDefault();
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'call',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url: `tel:${venue.phone}`,
      });
    }
    window.open(`tel:${venue.phone}`, '_blank');
  };

  const visitWebsite = (e) => {
    e.preventDefault();
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'view_website',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url: venue.website,
      });
    }
    window.open(venue.website, '_blank');
  };

  const visitFacebook = (e) => {
    e.preventDefault();
    const url = `https://facebook.com/${venue.facebook_id}`;
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'view_facebook',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url,
      });
    }
    window.open(url, '_blank');
  };

  const visitTwitter = (e) => {
    e.preventDefault();
    const url = `https://twitter.com/${venue.twitter_id}`;
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'view_twitter',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url,
      });
    }
    window.open(url, '_blank');
  };

  const visitInstagram = (e) => {
    e.preventDefault();
    const url = `https://instagram.com/${venue.instagram_id}`;
    if (window.analytics) {
      window.analytics.track('Venue Actions', {
        venue_id: venue.id,
        type: 'view_instagram',
        user_id: props.auth && props.auth.user && props.auth.user.id,
        url,
      });
    }
    window.open(url, '_blank');
  };

  // FIXME We use this quite a bit - turn it into a component
  const renderMoreOptionsSheet = () => {
    if (isIOS) {
      return (
        <div>
          <ActionSheet
            show={state.showMoreOptions}
            menus={moreOptions}
            onRequestClose={toggleMoreOptions}
            cancelText={messages[language]?.cancel || 'Cancel'}
          />
        </div>
      );
    }
    return (
      <div>
        <Drawer
          anchor="bottom"
          open={state.showMoreOptions}
          onClick={toggleMoreOptions}
          onRequestClose={toggleMoreOptions}
        >
          <div
            role="presentation"
            onClick={toggleMoreOptions}
            onKeyDown={toggleMoreOptions}
          >
            <List>
              {/* FIXME Render a cancel button? */}
              { moreOptions.map((item) => (
                <ListItem onClick={item.onClick}>
                  <ListItemText primary={item.content} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  };

  const renderAverageRatings = () => {
    const ratingCount = detailedRatings.total_ratings || 0;
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <Rating
            initialRating={detailedRatings.avg_rating || 0}
            emptySymbol="far fa-star"
            fullSymbol="fas fa-star"
            fractions={2}
            readonly
            style={{ color: 'rgb(214, 194, 144)' }}
          />
          <span style={{ marginLeft: 6 }}>
            {ratingCount}
            {` ${messages[language]?.ratings || 'ratings'}`}
          </span>
        </div>
        <span>{messages[language]?.selection || 'Selection'}</span>
        <RatingProgress variant="determinate" value={detailedRatings.avg_selection ? detailedRatings.avg_selection * 20 : 0} />
        <span>{messages[language]?.customer_service || 'Customer Service'}</span>
        <RatingProgress variant="determinate" value={detailedRatings.avg_service ? detailedRatings.avg_service * 20 : 0} />
        <span>{messages[language]?.atmosphere || 'Atmosphere'}</span>
        <RatingProgress variant="determinate" value={detailedRatings.avg_atmosphere ? detailedRatings.avg_atmosphere * 20 : 0} />
        <span>{messages[language]?.price || 'Price'}</span>
        <RatingProgress variant="determinate" value={detailedRatings.avg_price ? detailedRatings.avg_price * 20 : 0} />
      </div>
    );
  };

  const ratingValue = (value, totalRatings) => ((value && totalRatings) ? (value / totalRatings) * 100 : 0);

  const renderTotalRatings = () => {
    const {
      total_ratings,
      five_star_ratings,
      four_star_ratings,
      three_star_ratings,
      two_star_ratings,
      one_star_ratings,
    } = detailedRatings;
    return (
      <div>
        <Rating
          initialRating={5}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(five_star_ratings, total_ratings)} />
        <Rating
          initialRating={4}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(four_star_ratings, total_ratings)} />
        <Rating
          initialRating={3}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(three_star_ratings, total_ratings)} />
        <Rating
          initialRating={2}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(two_star_ratings, total_ratings)} />
        <Rating
          initialRating={1}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(one_star_ratings, total_ratings)} />
      </div>
    );
  };

  const renderImage = (image) => (
    <FancyBox
      tagName="a"
      className="rui-gallery-item"
      href={image.url}
      closeExisting
      popupClassName="rui-popup"
      galleryId="profile-gallery"
    >
      <img
        src={Resize.size(image.url, { width: 50, height: 50, cropType: 'crop' })}
        className="rui-img"
        // TODO Let businesses link alt tags to their media
        alt={image.alt || `${venue.name} Images`}
      />
    </FancyBox>
  );

  const periodAtIndex = (businessHours, index) => {
    for (let i = 0; i < businessHours.length; i++) {
      const period = businessHours[i];
      if (period.open && period.open.day === index) {
        return period;
      }
    }
    return null;
  };

  const renderHoursDisplay = (businessHours, day, index) => {
    const period = periodAtIndex(businessHours, index);
    // console.log(time);
    let openValue = 'Unknown';
    let closedValue = 'Unknown';
    if (period && (period.open || period.close)) {
      if (period.open) {
        let openHours = period.open.hours;
        const openSuffix = openHours >= 12 ? 'PM' : 'AM';
        openHours = (openHours % 12) || 12;
        const openMinutes = (period.open.minutes < 10 ? '0' : '') + period.open.minutes;
        openValue = `${openHours}:${openMinutes.slice(-2)} ${openSuffix}`;
      }

      if (period.close) {
        let closedHours = period.close.hours;
        const closedSuffix = closedHours >= 12 ? 'PM' : 'AM';
        closedHours = (closedHours % 12) || 12;
        const closedMinutes = (period.close.minutes < 10 ? '0' : '') + period.close.minutes;
        closedValue = `${closedHours}:${closedMinutes.slice(-2)} ${closedSuffix}`;
      }
      return (
        <div>
          <span>
            {day}:
          </span>
          <span style={{ float: 'right' }}>{`${openValue} - ${closedValue}`}</span>
        </div>
      );
    }
    return (
      <div>
        <span>
          {day}:
        </span>
        <span style={{ float: 'right' }}>Closed</span>
      </div>
    );
  };

  const renderBusinessHours = () => {
    console.log('Rendering business hours:');
    console.log(hours);
    const businessHours = days.map((_, index) => {
      // INFO This relies on them being returned in order - may not be the best solution
      if (hours[index]) {
        const hour = hours[index];
        // FIXME If either of these are present, we really don't need any of the others - hopefully they can either be removed from the table or filtered out server-side
        if (hour.permanently_closed || hour.always_open) {
          return hour;
        }
        return ({
          open: {
            day: hour.open_day,
            time: hour.open_time && hour.open_time.toString().padStart(4, '0'),
            hours: hour.open_time && hour.open_time.toString().padStart(4, '0').substring(0, 2),
            minutes: hour.open_time && hour.open_time.toString().padStart(4, '0').substring(2, 4),
          },
          close: {
            day: hour.close_day,
            time: hour.close_time && hour.close_time.toString().padStart(4, '0'),
            hours: hour.close_time && hour.close_time.toString().padStart(4, '0').substring(0, 2),
            minutes: hour.close_time && hour.close_time.toString().padStart(4, '0').substring(2, 4),
          },
        });
      }
      return ({
        open: false,
        close: false,
      });
    });
    return (
      <div>
        {businessHours && businessHours.length > 0 && businessHours[0].permanently_closed && (
          <div>Permanently Closed</div>
        )}
        {businessHours && businessHours.length > 0 && businessHours[0].always_open && (
          <div>Always Open</div>
        )}
        {days.map((day, i) => (
          <div>{renderHoursDisplay(businessHours, day, i)}</div>
        ))}
      </div>
    );
  };

  const renderPhotoGrid = () => {
    // TODO Loading skeleton views here
    if ((!images || !images.length) && (!venue.media || !venue.media.length) && (!checkinImages || !checkinImages.length)) {
      return (
        <Card style={{ padding: 20, marginBottom: 20 }}>
          <h3>{messages[language]?.photos || 'Photos'}</h3>
          <span>{'This location doesn\'t have any photos yet. Check In above to add yours!'}</span>
        </Card>
      );
    }
    const mainImages = [...checkinImages];
    const thumbnailImages = [...checkinThumbnailImages];

    // FIXME CNM Had "Owner added" (linked to the venue directly, ie "images") and "User added" (from checkins, ie "checkinImages") tabs
    if (images && images.length) {
      console.log(images);
      images.forEach((image) => {
        console.log(image);
        mainImages.push(image.url.indexOf('//') !== -1 ? image.url : `//${image.url}`);
        const thumbnail = Resize.thumbnail(image.url);
        thumbnailImages.push(thumbnail.indexOf('//') !== -1 ? thumbnail : `//${thumbnail}`);
      });
    }
    return (
      <Card style={{ padding: 20, marginBottom: 20 }}>
        <h3>{messages[language]?.photos || 'Photos'}</h3>
        <div>
          <MediaGrid
            media={mainImages.map((url) => ({ src: url }))}
            // lazyImages={}
            thumbnailMedia={thumbnailImages.map((url) => ({ src: Resize.thumbnail(url) }))}
            direction="horizontal"
            countFrom={4}
            fancyBox
          />
        </div>
      </Card>
    );
  };

  const renderDetails = () => {
    const { settings } = props;
    const { night_mode } = settings;
    return (
      <Card style={{ padding: 20 }}>
        <h3>{messages[language]?.details || 'Details'}</h3>
        {!isHome && renderAvailability(venue)}
        {venue.description && (
          <ReadMore initialHeight={isMobile ? 250 : 600}>
            <div dangerouslySetInnerHTML={{ __html: venue.description }} />
          </ReadMore>
        )}
        {!venue.claimed && (
          <div>
            <hr />
            <div style={{ display: 'flex' }}>
              <h4 style={{ flex: 1 }}>Is this your business?</h4>
              {/* <Icon */}
              {/*  name="x" */}
              {/*  style={{ cursor: 'pointer' }} */}
              {/*  onClick={() => { */}
              {/*    // TODO Hide this card/block and store flag in ls */}
              {/*  }} */}
              {/* /> */}
            </div>
            <p>Claim your profile for free to instantly update business details and photos, respond to customer reviews, and more.</p>
            <div style={{ textAlign: 'center' }}>
              <MaterialButton variant="contained" style={{ marginTop: 10 }} onClick={() => props.history.push(`/venues/claim?id=${hashids.encode(venue.id)}&type=venue`, { business: venue, business_type: 'venue' })}>
                {'Claim This Location'}
              </MaterialButton>
            </div>
            <hr />
          </div>
        )}
        {hours && hours.length > 0 && (
          <div>
            {venue.claimed && <hr />}
            <span style={{ fontWeight: '700' }}>Business Hours</span>
            {renderBusinessHours()}
            <hr />
          </div>
        )}
        <div style={{ marginBottom: 20 }} />
        {(venue.location && venue.location.formatted_address) && (
          <div>
            <span>
              <Icon name="map-pin" style={{ marginRight: 6 }} />
              {venue.location ? venue.location.formatted_address : 'Address unavailable'}
            </span>
            {hasCoordinates(venue) && (
              <>
                {!isHome && (
                  <Button
                    outline
                    color="primary"
                    style={{ margin: '16px auto', textAlign: 'center', display: 'block' }}
                    size="lg"
                    onClick={getDirections}
                  >
                    <Icon className="get-directions-icon" name="map" style={{ marginRight: 6, color: '#d5c196' }} />
                    {'Get Directions'}
                  </Button>
                )}
                <GoogleMap
                  mapContainerClassName="rui-gmap-xsmall"
                  options={{
                    zoom: 10,
                    scrollwheel: false,
                    // mapTypeControl: false,
                    // streetViewControl: false,
                    center: { lat: venue.location.coordinate.latitude, lng: venue.location.coordinate.longitude },
                    // zoomControl: true,
                    zoomControlOptions: {
                      // position: window.google.maps.ControlPosition.LEFT_TOP,
                    },
                    disableDefaultUI: true,
                    styles: night_mode ? mapStylesDark : mapStylesLight,
                  }}
                >
                  <Marker
                    key={venue.id}
                    position={{
                      lat: venue.location.coordinate.latitude,
                      lng: venue.location.coordinate.longitude,
                    }}
                    icon={{
                      url: Placeholder.location.marker,
                      size: new google.maps.Size(42, 42),
                      origin: new google.maps.Point(0, 0),
                      anchor: new google.maps.Point(42, 42),
                      scaledSize: new google.maps.Size(42, 42),
                    }}
                    name={venue.name}
                  />
                </GoogleMap>
              </>
            )}
          </div>
        )}
        {/* FIXME If they put it in with iframe width="X" change it to width="100%" here and maybe set a specific height for all */}
        {venue.virtual_tour_embed && (
          <div style={{ width: '100%', marginTop: 12 }} dangerouslySetInnerHTML={{ __html: venue.virtual_tour_embed }} />
        )}
        {venue.phone && (
          <span style={{ marginTop: 5 }}>
            <Icon name="phone" style={{ marginRight: 6 }} />
            {venue.phone}
          </span>
        )}
        {venue.website && (
          <span style={{ marginTop: 5, cursor: 'pointer' }} onClick={visitWebsite}>
            <Icon name="globe" style={{ marginRight: 6 }} />
            {venue.website}
          </span>
        )}
        {venue.facebook_id && (
          <span style={{ marginTop: 5, cursor: 'pointer' }} onClick={visitFacebook}>
            <Icon name="facebook" style={{ marginRight: 6 }} />
            /
            {venue.facebook_id}
          </span>
        )}
        {venue.twitter_id && (
          <span style={{ marginTop: 5, cursor: 'pointer' }} onClick={visitTwitter}>
            <Icon name="twitter" style={{ marginRight: 6 }} />
            @
            {venue.twitter_id}
          </span>
        )}
        {venue.instagram_id && (
          <span style={{ marginTop: 5, cursor: 'pointer' }} onClick={visitInstagram}>
            <Icon name="instagram" style={{ marginRight: 6 }} />
            @
            {venue.instagram_id}
          </span>
        )}
      </Card>
    );
  };

  const renderAll = () => {
    const { loadingSessions, loadingCheckins, loadingReviews } = state;
    const loadingPosts = loadingSessions || loadingCheckins || loadingReviews;
    const allPosts = smokeSessions.map((session) => ({
      ...session,
      type: 'session',
    })).concat(recentCheckins.map((checkin) => ({
      ...checkin,
      type: 'checkin',
    }))).concat(posts.map((post) => ({
      ...post,
      type: post.type,
    }))).concat(events.map((event) => ({
      ...event,
      type: 'event',
    }))).sort((x, y) => {
      if (x.timestamp && y.timestamp) {
        const a = dateFromTimestamp(x.timestamp);
        const b = dateFromTimestamp(y.timestamp);
        if (a > b) {
          return -1;
        }
        if (a < b) {
          return 1;
        }
      }
      return 0;
    });
    return (
      <div>
        {loadingPosts && <CardListSkeleton />}
        {
          !loadingPosts
          && smokeSessions.length === 0
          && recentCheckins.length === 0
          && posts.length === 0
          && events.length === 0
          && <div style={{ textAlign: 'center', margin: 20 }}>No items</div>
        }
        {!loadingPosts && allPosts.map((post) => {
          if (post.type === 'session') {
            return <FeedSessionCard key={post.id} session={post} showTitle />;
          }
          if (post.type === 'checkin') {
            return <FeedCheckinCard key={post.id} checkin={post} />;
          }
          if (post.type === 'business_post') {
            console.log('Rendering post');
            return <BusinessPostCardList key={post.id} post={post.business_post} showTitle />;
          }
          if (post.type === 'blog_article') {
            console.log('Rendering blog article');
            return <BlogArticleCard key={post.id} item={post.blog_article} showTitle />;
          }
          if (post.type === 'podcast_episode') {
            console.log('Rendering podcast episode');
            return <PodcastEpisodeCard key={post.id} episode={post.podcast_episode} post={post} />;
          }
          if (post.type === 'event') {
            return <FeedEventCard key={post.id} event={post} showTitle />;
          }
          return null;
        })}
      </div>
    );
  };

  const renderChip = ({ icon, name, index }) => (
    <div style={{ margin: 5, display: 'inline-block' }}>
      <div>
        <Chip
          icon={icon && <Icon name={icon} />}
          color="default"
          label={name}
          onClick={() => setState((prevState) => ({ ...prevState, activeSecondaryTab: index }))}
        />
      </div>
    </div>
  );

  const renderTabs = () => {
    const { activeSecondaryTab } = state;
    const minHeight = 400; // window.innerHeight - 110; // FIXME This scrolls past the text if the previous tab was scrolled past that y index
    return (
      <div>
        {isMobileOnly && (
          <div id="venue-extra-tabs">
            <Tabs
              value={activeSecondaryTab}
              onChange={toggleSecondaryTab}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              aria-label="Venue Social Tabs"
              centered={!isMobile}
            >
              <Tab label={isMobile ? 'All' : 'All Activity'} />
              {!isHome && <Tab label={isMobile ? 'Product Reviews' : 'Product Reviews'} />}
              {!isHome && <Tab label={isMobile ? 'Checkins' : 'Checkins'} />}
              <Tab label={isMobile ? 'Smoke Sessions' : 'Smoke Sessions'} />
              {!isHome && <Tab label={isMobile ? 'Posts' : 'Posts'} />}
              <Tab label={isMobile ? 'Events' : 'Events'} />
            </Tabs>
          </div>
        )}
        {!isMobileOnly && (
          <div
            style={{
              height: isMobile ? 44 : 60,
              whiteSpace: 'nowrap',
              maxWidth: '100%',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {renderChip({
              icon: null,
              name: 'All Activity',
              index: 0,
            })}
            {!isHome && renderChip({
              icon: null,
              name: 'Product Reviews',
              index: 1,
            })}
            {!isHome && renderChip({
              icon: null,
              name: 'Check Ins',
              index: 2,
            })}
            {renderChip({
              icon: null,
              name: 'Smoke Sessions',
              index: isHome ? 1 : 3,
            })}
            {!isHome && renderChip({
              icon: null,
              name: 'Posts',
              index: 4,
            })}
            {renderChip({
              icon: null,
              name: 'Events',
              index: isHome ? 2 : 5,
            })}
          </div>
        )}
        <div>
          <TabPanel value={activeSecondaryTab} index={0}>
            {renderAll()}
          </TabPanel>
          <TabPanel value={activeSecondaryTab} index={isHome ? null : 1}>
            {products.length === 0 && <div style={{ minHeight: isMobile ? minHeight : 'auto', margin: 20, textAlign: 'center' }}>No products found</div>}
            {/* TODO FB does this as a 2 column grid */}
            {products.length > 0 && products.map((product) => (
              <ProductCardList item={product} />
            ))}
          </TabPanel>
          <TabPanel value={activeSecondaryTab} index={isHome ? null : 2}>
            {recentCheckins.length === 0 && <div style={{ minHeight: isMobile ? minHeight : 'auto', margin: 20, textAlign: 'center' }}>Looks like this shop does not have any check ins yet!</div>}
            {recentCheckins.length > 0 && recentCheckins.map((checkin) => (
              <FeedCheckinCard checkin={checkin} />
            ))}
          </TabPanel>
          <TabPanel value={activeSecondaryTab} index={isHome ? 1 : 3}>
            {smokeSessions.length === 0 && <div style={{ minHeight: isMobile ? minHeight : 'auto', margin: 20, textAlign: 'center' }}>Looks like no one has had a smoke session here yet!</div>}
            {smokeSessions.length > 0 && smokeSessions.map((session) => (
              <FeedSessionCard session={session} showTitle />
            ))}
          </TabPanel>
          <TabPanel value={activeSecondaryTab} index={isHome ? null : 4}>
            {posts.length === 0 && <div style={{ minHeight: isMobile ? minHeight : 'auto', margin: 20, textAlign: 'center' }}>Looks like this shop hasn't added any posts yet!</div>}
            {posts.length > 0 && posts.sort((x, y) => {
              if (x.timestamp && y.timestamp) {
                const a = dateFromTimestamp(x.timestamp);
                const b = dateFromTimestamp(y.timestamp);
                if (a > b) {
                  return -1;
                }
                if (a < b) {
                  return 1;
                }
              }
              return 0;
            }).map((post) => {
              if (post.type === 'business_post') {
                console.log('Rendering post');
                return <BusinessPostCardList key={post.id} post={post.business_post} showTitle />;
              }
              if (post.type === 'blog_article') {
                console.log('Rendering blog article');
                return <BlogArticleCard key={post.id} item={post.blog_article} showTitle />;
              }
              if (post.type === 'podcast_episode') {
                console.log('Rendering podcast episode');
                return <PodcastEpisodeCard key={post.id} episode={post.podcast_episode} post={post} />;
              }
            })}
          </TabPanel>
          <TabPanel value={activeSecondaryTab} index={isHome ? 2 : 5}>
            {events.length === 0 && <div style={{ minHeight: isMobile ? minHeight : 'auto', margin: 20, textAlign: 'center' }}>No upcoming events</div>}
            {events.length > 0 && events.map((event) => (
              <FeedEventCard event={event} showTitle />
            ))}
          </TabPanel>
        </div>
      </div>
    );
  };

  const renderCheckInModal = () => (
    <CheckinEditor
      open={state.showCheckInModal}
      venue={venue}
      toggle={toggleCheckInModal}
      onClose={closeCheckInModal}
      // onSave={(checkin) => {
      //   // INFO No longer needed with Pullstate
      // }}
    />
  );

  const renderTaggedUsersModal = () => (
    <ModalView
      title="Select users"
      open={state.showTaggedUsersModal}
      showFrom="bottom"
      hideAppBarShadow
      onClick={toggleTaggedUsersModal}
      onClose={closeTaggedUsersModal}
      rightButtons={[
        <MaterialButton aria-label="Next" onClick={() => {}}>Next</MaterialButton>,
      ]}
    >
      <UserSearch
        multiSelect
        onSelect={(users) => {
          console.log('Selected tagged users:');
          console.log(users);
          setState({
            ...state,
            selectedTaggedUsers: users, // This will always be an array
          });
        }}
      />
    </ModalView>
  );

  const renderReportDataModal = () => (
    <VenueEditor
      venue={venue}
      open={showVenueModal}
      toggle={() => setShowVenueModal(!showVenueModal)}
      onClose={() => setShowVenueModal(false)}
    />
  );

  const { loading, activeTab } = state;
  const { settings, match } = props;

  // FIXME I don't think we want a loading screen here - just set certain elements within the main layout to skeleton views
  // if (loading) {
  //   return (
  //     <PageWrap>
  //       <div>
  //
  //       </div>
  //     </PageWrap>
  //   );
  // }

  const insetTop = safeAreaInsets.top;
  const { width } = window.screen;

  const previewDescription = () => {
    if (venue.description && venue.description.length > 0) {
      return venue.description;
    }
    return `${venue.name} is located at ${venue.location?.formatted_address || venue.website}. ${venue.can_buy ? 'Buy cigars at competitive prices. ' : ''}${detailedRatings.total_ratings && detailedRatings.avg_rating ? `It has a ${detailedRatings.avg_rating}/5.0 average rating on Boxpressd from ${detailedRatings.total_ratings} users.` : 'View now on Boxpressd.'}`;
  };

  const metaProps = {
    type: 'LocalBusiness', // FIXME What if online only?
    title: venue.name + (venue.can_buy ? '. Best cigar shop' : (venue.can_smoke ? '. Best cigar lounge' : '') + (venue.address ? ` in ${venue.address.city}, ${venue.address.state}` : (venue.can_buy_online ? ' shop at ' + venue.website : ''))),
    description: previewDescription(),
    imageUrl: Venue.getCoverImage(venue) || Venue.getImage(venue),
    phone: venue.phone,
    address: venue.location,
    coordinates: venue.location && venue.location.coordinate,
    ratingCount: detailedRatings.total_ratings || null,
    avgRating: detailedRatings.avg_rating || null,
    reviews: recentCheckins.map((checkin) => ({
      user: checkin.user,
      advance_rating: checkin.advance_rating,
    })),
  };

  if (isMobileOnly) {
    return (
      <PageWrap>
        <PageMeta {...metaProps}>
          <div className="rui-profile">
            <div
              className="cover-photo"
              style={{
                backgroundImage: `url(${venueCoverImage(venue)}), url(https://cdn.boxpressd.io/placeholder/venue_bg.jpg)`,
                backgroundPosition: 'center',
                height: 125,
              }}
            />
            <div style={{ position: 'relative', display: 'block', marginTop: -45, marginLeft: 25 }}>
              <Avatar src={venueProfileImage(venue)} style={{ width: 82, height: 82, fontSize: 32, border: `4px solid ${darkMode ? '#454545' : '#ffffff'}` }}>{(venue.name && venue.name.charAt(0)) || '-'}</Avatar>
            </div>
            <div style={{ right: 10, position: 'absolute', top: width > 320 ? `${(240 + insetTop)}px` : `${(270 + insetTop)}px` }}>
              { venue.website && (
                <Button outline style={{ float: 'right' }} onClick={visitWebsite}>
                  <Icon name="globe" />
                  <div style={{ display: width > 320 ? 'inherit' : 'none' }}>{messages[language]?.website || 'Website'}</div>
                </Button>
              )}
              { !isHome && venue.location && venue.location.formatted_address && (
                <Button outline style={{ float: 'right' }} onClick={getDirections}>
                  <Icon name="navigation" />
                  <div style={{ display: width > 320 ? 'inherit' : 'none' }}>{messages[language]?.directions || 'Directions'}</div>
                </Button>
              )}
              { venue.phone && (
                <Button outline style={{ float: 'right' }} onClick={callLocation}>
                  <Icon name="phone" />
                  <div style={{ display: width > 320 ? 'inherit' : 'none' }}>{messages[language]?.call || 'Call'}</div>
                </Button>
              )}
            </div>
            <Card style={{ marginTop: 40, marginBottom: 20 }}>
              {isHome && (
                <Button
                  style={{ width: 'calc(100% - 40px)', textAlign: 'center', margin: '15px 20px', marginTop: -20, display: 'inline-block' }}
                  color="success"
                  size="lg"
                  onClick={() => {
                    // FIXME This isn't resetting the z-index after
                    jQuery('#mobile-search-input').focus();
                    window.dispatchEvent(new CustomEvent('showSearch', {
                      detail: {
                        action: {
                          route: 'cigars/:uid',
                          params: '?action=smoke_now',
                          results: 'cigars',
                          replace: false,
                        },
                      },
                    }));
                  }}
                >
                  {messages[language]?.smoke_now || 'Smoke Now'}
                </Button>
              )}
              {!isHome && (
                <Button style={{ width: 'calc(100% - 40px)', textAlign: 'center', margin: '15px 20px', marginTop: -20, display: 'inline-block' }} color="success" size="lg" onClick={toggleCheckInModal}>
                  {messages[language]?.checkin || 'Check In'}
                </Button>
              )}
              {venue.products && venue.products.length > 0 && (
                <Button outline style={{ width: 'calc(100% - 40px)', textAlign: 'center', margin: '15px 20px', marginTop: -5, display: 'inline-block' }} color="success" size="lg" onClick={toggleShopNowModal}>Shop Now</Button>
              )}
              <h1 id="venue-name" style={{ fontSize: 18, marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
                {venue.claimed && <Icon vendor="fa" name={['fas', 'check-circle']} style={{ fill: '#d5c290', color: '#d5c290', marginRight: 8, fontSize: '22px' }} />}
                {!venue.claimed && venue.verified && <Icon name="check-circle" style={{ color: '#d5c290', marginRight: 8, fontSize: '22px' }} />}
                {venue.name}
              </h1>
              <div className="divider" />
              <div style={{ marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                <Button outline style={{ float: 'right' }} onClick={toggleMoreOptions}>
                  <Icon name="more-horizontal" style={{ marginRight: 6 }} />
                </Button>
                <Button outline style={{ float: 'right' }} onClick={toggleFollowing}>
                  <Icon name="rss" style={{ marginRight: 6 }} />
                  {itemFollowed ? 'Unfollow' : messages[language]?.follow || 'Follow'}
                </Button>
                <Button outline style={{ float: 'right' }} onClick={toggleSaved}>
                  <Icon name={itemSaved ? 'bookmark' : 'bookmark-border'} vendor="material" style={{ marginRight: 6 }} />
                  {itemSaved ? messages[language]?.unsave || 'Unsave' : messages[language]?.save || 'Save'}
                </Button>
              </div>
            </Card>
            {products && products.length > 0 && (
              <Card style={{ marginBottom: 20, paddingTop: 20 }}>
                <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', width: '100%' }}>
                  <h5 style={{ marginBottom: 0, width: 'auto', flex: 1 }}>{messages[language]?.best_sellers || 'Best Sellers'}</h5>
                  <Link to={`/shop/all?venue_id=${venue.hash_id}`}>View All</Link>
                </div>
                <div className="simple-carousel">
                  {products.slice(0, 8).map((product) => (
                    <div style={{ minWidth: 200 }}>
                      <ProductCardList
                        key={product.id}
                        item={product}
                        orientation="vertical"
                        hideDescription
                        hideHeader
                      />
                    </div>
                  ))}
                </div>
              </Card>
            )}
            {!isHome && (
              <Card style={{ marginBottom: 20, padding: 20 }}>
                <h5>{messages[language]?.average_customer_rating || 'Average Customer Rating'}</h5>
                { renderAverageRatings() }
              </Card>
            )}
            { renderPhotoGrid() }
            { renderDetails() }
            { renderTabs() }
          </div>

          { state.showCheckInModal && renderCheckInModal() }
          { renderMoreOptionsSheet() }
          { renderTaggedUsersModal() }
          { renderReportDataModal() }
        </PageMeta>
      </PageWrap>
    );
  }

  return (
    <PageWrap>
      <PageMeta {...metaProps}>
        <div style={{ width: '100%', maxWidth: 1024, margin: '0 auto' }}>
          <div className="rui-profile row vertical-gap" style={{ margin: isTablet ? -50 : -30 }}>
            <div className="cover-photo" style={{ backgroundImage: `url(${venueCoverImage(venue)}), url(https://cdn.boxpressd.io/placeholder/venue_bg.jpg)` /* backgroundPositionY: -330 */ }} />
            <div
              className="rui-profile-img"
              style={{
                width: isTablet ? 90 : 200,
                height: isTablet ? 90 : 200,
                maxWidth: isTablet ? 90 : 200,
                marginTop: isTablet ? -45 : -110,
                marginLeft: isTablet ? 30 : 50,
                border: `4px solid ${darkMode ? '#454545' : '#ffffff'}`,
                // boxShadow: '0px 1px 8px #959595',
              }}
            >
              <div style={{ position: 'relative', display: 'block', height: 200, width: 200, marginLeft: -4, marginTop: -4 }}>
                <div className={state.showProfileUpload ? 'store_sde-upp1 show' : 'store_sde-upp1'}>
                  <Icon name="camera" style={{ marginRight: 10 }} />
                  <span className="upp-title1">
                    {'Update Profile'}
                    <br />
                    {'Picture'}
                  </span>
                </div>
                {/* FIXME I'd rather have some default photo here on desktop I think... this looks a little off */}
                <Avatar src={venueProfileImage(venue)} alt={venue.name} style={{ height: isTablet ? 90 : 200, width: isTablet ? 90 : 200, fontSize: 32 }}>{(venue.name && venue.name.charAt(0)) || '-'}</Avatar>
              </div>
            </div>
            <div style={{ position: 'relative', top: -70, left: 20 }}>
              <h1 id="venue-name" style={{ marginBottom: 5, color: '#ffffff', textShadow: '0 1px 3px rgba(39, 39, 39, 0.8)' }}>{venue.name || '...'}</h1>
              {venue.claimed
                ? <Badge color="primary" pill>Claimed</Badge>
                : (
                  <Link>
                    <Badge
                      color="primary"
                      pill
                      onClick={() => {
                        props.history.push(`/venues/claim?id=${hashids.encode(venue.id)}`, { business: venue });
                      }}
                    >
                      {'Unclaimed'}
                    </Badge>
                  </Link>
                )}
            </div>
            <div className="right-actions" style={{ position: 'absolute', top: `${(isTablet ? 310 : 330 + insetTop)}px` }}>
              <Button style={{ borderRadius: 15 }} onClick={() => setShowVenueModal(true)}>Report incorrect data</Button>
            </div>
            <div className="right-actions" style={{ position: 'absolute', top: `${(isTablet ? 368 : 385 + insetTop)}px`, zIndex: 1000 }}>
              <Button outline>
                <Icon name="bookmark" style={{ marginRight: 6 }} />
                {messages[language]?.save || 'Save'}
              </Button>
              <Button outline>
                <Icon name="rss" style={{ marginRight: 6 }} />
                Follow
              </Button>
            </div>
          </div>
          <div style={{ marginTop: isMobile ? -30 : -80, marginLeft: isMobile ? 90 : 250 }}>
            <RoutedTabs sliding>
              <RoutedTabs.NavItem
                isActive={state.activeTab === 'all'}
                to={`${match.url}`}
              >
                {'Home'}
              </RoutedTabs.NavItem>
              {products && products.length > 0 && (
                <RoutedTabs.NavItem
                  isActive={activeTab === 'shop'}
                  to={`${match.url}/shop`}
                >
                  {'Shop'}
                </RoutedTabs.NavItem>
              )}
              {/* <RoutedTabs.NavItem */}
              {/*  isActive={state.activeTab === 'checkins'} */}
              {/*  to={`${match.url}/checkins`} */}
              {/* > */}
              {/*  {'Check Ins'} */}
              {/* </RoutedTabs.NavItem> */}
              {/* <RoutedTabs.NavItem */}
              {/*  isActive={state.activeTab === 'sessions'} */}
              {/*  to={`${match.url}/smoke-sessions`} */}
              {/* > */}
              {/*  {'Smoke Sessions'} */}
              {/* </RoutedTabs.NavItem> */}
              <RoutedTabs.NavItem
                isActive={activeTab === 'media'}
                to={`${match.url}/media`}
              >
                {'Photos'}
              </RoutedTabs.NavItem>
              <RoutedTabs.NavItem
                isActive={activeTab === 'events'}
                to={`${match.url}/events`}
              >
                {'Events'}
              </RoutedTabs.NavItem>
            </RoutedTabs>
          </div>
        </div>
        <div style={{ marginTop: 60 }}>
          <RoutedTabs.Content activeTab={activeTab}>
            <RoutedTabs.Pane path={`${match.url}`}>
              <div style={{ maxWidth: 1000, margin: 'auto' }}>
                {!isHome && (
                  <Card style={{ padding: 30 }}>
                    <Row style={{ backgroundColor: settings.night_mode ? '#24262a' : '#ffffff' }}>
                      <Col sm={12} style={{ borderBottom: '1px solid #eeeeee', marginBottom: 20 }}>
                        <h3 style={{ float: 'left' }}>Customer Check Ins</h3>
                        <Button style={{ textAlign: 'center', float: 'right', marginTop: -12 }} color="success" size="lg" onClick={toggleCheckInModal}>Check In</Button>
                      </Col>
                    </Row>
                    <Row style={{ backgroundColor: settings.night_mode ? '#24262a' : '#ffffff' }}>
                      <Col md={6} style={{ borderRight: '1px solid #eeeeee' }}>
                        <h5>{ messages[language]?.average_customer_rating || 'Average Customer Rating'}</h5>
                        { renderAverageRatings() }
                      </Col>
                      <Col md={6}>
                        <h5>
                          {`Average Rating Based On ${detailedRatings.total_ratings || 0} Ratings`}
                        </h5>
                        { renderTotalRatings() }
                      </Col>
                    </Row>
                  </Card>
                )}
                <Row style={{ marginTop: 20 }}>
                  <Col md={8}>
                    { renderTabs() }
                  </Col>
                  <Col md={4}>
                    { renderDetails() }
                    <Card style={{ marginTop: 20, padding: 20 }}>
                      <h3>Amenities</h3>
                      {(!amenities || !amenities.length) && (
                        <div>Amenities unknown</div>
                      )}
                      {amenities && amenities.length > 0 && (
                        <div>
                          <ul style={{ listStyle: 'none', padding: '0 10px' }}>
                            {/* FIXME Include a "show all" button? */}
                            {amenities.slice(0, 10).map((amenity) => <li style={{ padding: '6px auto' }}>{amenity}</li>)}
                          </ul>
                        </div>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>
            </RoutedTabs.Pane>
            <RoutedTabs.Pane path={`${match.url}/media`}>
              <div style={{ maxWidth: 1000, margin: 'auto' }}>
                {!images.length && (
                  <div style={{ textAlign: 'center' }}>No images</div>
                )}
                <div className="media-content">
                  <div className="row vertical-gap sm-gap rui-gallery">
                    {images.map((image) => (
                      <div className="col-6 col-md-3 col-lg-2">
                        <FancyBox
                          tagName="a"
                          className="rui-gallery-item"
                          href={image.url.indexOf('//') !== -1 ? image.url : `//${image.url}`}
                          closeExisting
                          popupClassName="rui-popup"
                          galleryId="profile-gallery"
                        >
                          <span className="rui-gallery-item-overlay rui-gallery-item-overlay-md">
                            <Icon name="maximize" />
                          </span>
                          <img
                            src={image.url.indexOf('//') !== -1 ? image.url : `//${image.url}`}
                            className="rui-img"
                            alt=""
                          />
                        </FancyBox>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </RoutedTabs.Pane>
            <RoutedTabs.Pane path={`${match.url}/events`}>
              {/* TODO */}
              <div style={{ textAlign: 'center' }}>No upcoming events</div>
            </RoutedTabs.Pane>
            {products && products.length > 0 && (
              <RoutedTabs.Pane path={`${match.url}/shop`}>
                <div style={{ maxWidth: 1080, margin: 'auto' }}>
                  {/* FIXME Should be 4-5 columns on desktop */}
                  <div className="row">
                    {products.slice(0, 8).map((product) => (
                      <div className="col-md-3">
                        <ProductCardList
                          key={product.id}
                          item={product}
                          orientation="vertical"
                          hideDescription
                          hideHeader
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: -180,
                      paddingTop: 80,
                      paddingBottom: 62,
                      background: darkMode ? 'linear-gradient(transparent, black)' : 'linear-gradient(transparent, white)',
                      position: 'relative',
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => props.history.push(`/shop/all?venue_id=${venue.hash_id}`, { venue_id: venue.id })}
                      style={{ width: '95%', color: '#121212' }}
                    >
                      {'View All Products'}
                    </Button>
                  </div>
                </div>
              </RoutedTabs.Pane>
            )}
          </RoutedTabs.Content>
        </div>

        { state.showCheckInModal && renderCheckInModal() }
        { renderReportDataModal() }
      </PageMeta>
    </PageWrap>
  );
}

function mapStateToProps(state) {
  return {
    // FIXME These are both now in stores/index.js UserStore and SettingsStore
    auth: state.get('auth').toJS(),
    settings: state.get('settings'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VenueDetails));
